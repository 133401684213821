<template>
  <div id="app" class="container">
    <player-zone
      v-for="zone in zones"
      :key="zone.id"
      :zone="zone"
    ></player-zone>
  </div>
</template>

<script>
import CreateEventEmitter from "@/events/EventEmitter";

export default {
  name: "Web",
  components: {
    PlayerZone: () => import("@/components/core/PlayerZone"),
  },
  props: {
    //
  },
  data() {
    return {
      zones: {},
      eventEmitter: CreateEventEmitter(),
      //
    };
  },
  created() {
    this.eventEmitter.listen(this.handleEvent);
  },
  mounted() {
    this.eventEmitter.emit("anycontent-loaded");
  },
  computed: {
    zoneList: function() {
      var zns = Object.keys(this.zones).map((key) => {
        return this.zones[key];
      });
      return zns;
    },
  },
  methods: {
    handleEvent: function(data) {
      switch (data.event) {
        case "play":
          this.play(data);
          break;

        case "stop":
          this.stop(data);
          break;

        case "stopAll":
          this.stopAll(data);
          break;
      }
    },
    play: function(data) {
      console.log("data", data);

      data.zone.layout.skeleton = false;

      // fix scaling issues for FireTV
      data.zone.layout.applyScale = true;

      this.$set(this.zones, data.zone.id, data.zone);
      //this.zones[data.zone.id] = data.zone;

      var duration = 10000;
      if (data.zone.layout) {
        duration = data.zone.layout.duration;
      }

      setTimeout(() => {
        this.eventEmitter.emit("anycontent-play-next", {
          zoneId: data.zone.id,
        });
      }, duration);
    },
    stop: function(data) {
      if (data && data.zoneId) {
        //this.$set(this.zones, data.zoneId, null);
        this.$delete(this.zones, data.zoneId);
      }
    },
    stopAll: function() {
      Object.keys(this.zones).forEach((zId) => this.$delete(this.zones, zId));
    },
  },
};
</script>

<style></style>
