import BrightSignEmitter from "./BrightsignEmitter";
import WebEmitter from "./WebEmitter";

export default function CreateEventEmitter() {
  if (typeof BSMessagePort === "function") {
    console.log("BrightSignEmitter created");
    return new BrightSignEmitter();
  }

  console.log("WebEmitter created");
  return new WebEmitter();
}
