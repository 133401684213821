export default class WebEmitter {
  constructor() {
    this.callback = {};

    window.addEventListener(
      "message",
      (msg) => {
        if (this.checkOrigin(msg))
          if (this.callback) {
            this.callback(msg.data);
            return;
          }
        console.log("event not recognized!", msg.data);
      },
      false
    );
  }

  emit(event, data) {
    window.parent.postMessage({ event: event, data: data }, "*");
  }

  listen(callback) {
    this.callback = callback;
  }

  // eslint-disable-next-line no-unused-vars
  checkOrigin(event) {
    // const allowedOrigins = [
    //   "http://localhost:3000",
    //   "https://play-dev.wallin.tv",
    //   "https://play-dev.wallinone.tv",
    //   "https://play.wallin.tv",
    //   "https://play.wallinone.tv",
    //   "https://play.22hbg.com",
    //   "https://play.smartikette.it",
    //   "https://signage.easytech.it/",
    // ];
    // console.log("");
    // console.log(`event.origin -> ${event.origin}`);
    // console.log("");
    return true;
    //return allowedOrigins.indexOf(event.origin) >= 0;
  }
}
